import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// import Login from '../pages/Login/Login';
// import ProtectedRoute from './ProtectedRoute';
// import Users from '../pages/Users/Users';
import Dashboard from '../pages/Dashboard/Dashboard';

const Navigation = () => {
    // const location = useLocation();

    const navigate = useNavigate()

    // useEffect(() => {
    //     const checkAuth = () => {
    //         const userLoggedIn = localStorage.getItem('userLoggedIn');
    //         console.log('userLoggedIn', userLoggedIn)
    //         if (userLoggedIn) {
    //             navigate('/')
    //         } else {
    //             navigate('/login')
    //         }
    //     };
    //     checkAuth();
    // }, []);


    // const showHeaderFooter = location.pathname !== '/';

    return (
        <div>
            {/* {showHeaderFooter && <Header />} */}
            <Routes>
                {/* <Route path="/login" element={<Login />} /> */}
                <Route path="/" element={<Dashboard />} />
                {/* <Route path="/user" element={<Users />} /> */}
                {/* <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/user" element={<Users />} />
                </Route> */}

            </Routes>
            {/* {showHeaderFooter && <Footer />} */}
        </div>
    )
}

export default Navigation