// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useToast from '../hooks/useToast';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);


export const AuthProvider = ({ children }) => {
  const showToast = useToast();
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  // const login = (userData) => {
  //   console.log('userData', userData)
  //   if (userData.username === "admin@gmail.com" && userData.password === '1234') {
  //     setIsAuthenticated(true);
  //     localStorage.setItem('userLoggedIn', true);
  //     navigate('/');
  //   } else {
  //     showToast("Please enter Username and password", "warning");
  //   }
  // };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('userLoggedIn');
  };

  return (
    <AuthContext.Provider value={{ }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider
