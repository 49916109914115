import React, { useEffect, useState } from 'react';
import cardData from './cardData'; // Your dummy card data
import './Dashboard.css'; // Import your external styles
import DashboardNavbar from '../../components/Header';
import DashboardCard from '../../components/DashboardCard';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
// import ReactPaginate from 'react-paginate';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const cardsPerPage = 16;
  const [currentPage, setCurrentPage] = useState(0);

  // Get the cards for the current page
  const displayedCards = data.slice(
    currentPage * cardsPerPage,
    (currentPage + 1) * cardsPerPage
  );

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Make GET request to the API
        const response = await axios.get("https://offerwaala-api.onrender.com/offerwaala/offer/offers");
        // Set the data in state
        setData(response?.data?.offers);
      } catch (err) {
        // Handle error
        setError(err.message);
      } finally {
        // Set loading to false once the request is complete
        setLoading(false);
      }
    };

    fetchData();  // Call the fetch function
  }, []); 


  console.log('data', data)
  return (
    <div className="dashboard-content">
    <DashboardNavbar /> {/* Navbar at the top */}
    <Container className="dashboard-layout mt-5">
      <Row className="cards-section">
        {displayedCards.map((card) => (
          <Col key={card.id} xs={5} md={4} lg={2} className="mb-2">
            <DashboardCard
              title={card.title}
              text={card.time}
              imgUrl={card.imgUrl}
              brandImg={card.brandImg}
              time={card.time}
              discount={card.discount}
              originalPrice={card.originalPrice}
              offerPrice={card.offerPrice}
            />
          </Col>
        ))}
      </Row>
      <div className="extra-space">
        <h4>Right side content</h4>
        <p>This is some space for additional content or widgets.</p>
      </div>

      {/* Pagination Component */}
      {/* <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(data.length / cardsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      /> */}
    </Container>
  </div>
  );
};

export default Dashboard;
