// DashboardCard.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './DashboardCard.css'; // Import external CSS for additional styling
import moment from 'moment';

const DashboardCard = ({ title, imgUrl, brandImg, time, offerPrice,discount,originalPrice }) => {

  const imageSrc = `data:image/jpeg;base64,${imgUrl}`;
  const brandSrc = `data:image/jpeg;base64,${brandImg}`;

  return (
    <Card className="custom-card" style={{ padding: '0', margin: '0' }}>
      <div className="card-image-wrapper">
        <Card.Img variant="top" className="card-image" src={imageSrc} />
      </div>
      <Card.Body style={{ padding: '2px' }}>
        <Card.Text className="card-title">{truncateText(title)}</Card.Text>
        <div className="price-section">
          <div className='discount-info' >
            <Card.Text className='discount-style'>{`${parseInt(discount)}%`}</Card.Text>
            <Card.Text className='original-style'>{`₹${originalPrice}`}</Card.Text>
          </div>
          <Card.Text className='offer-style'>{`₹${offerPrice}`}</Card.Text>
        </div>
        <Card.Text className='date-style'>
          
        {
          moment(time).isSame(moment(), 'day')
            ? moment(time).format('h:mm a') // Show time if current date
            : moment(time).format('DD-MM-YYYY') // Show date if not current date
        }
          
          
          {/* {moment(time).format('DD-MM-YYYY')} {moment(time).format('hh:mm a')} */}
          </Card.Text>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <Card.Img variant="top" src={brandSrc} className="brand-img" />
          <Button style={{ width: 73, height: 28, fontSize: 10 }} variant="primary">Shop now</Button>
        </div>
      </Card.Body>
    </Card>
  );
};

// Helper function to truncate text
const truncateText = (text) => {
  return text.length > 30 ? text.substring(0, 40) + "..." : text;
};

export default DashboardCard;
