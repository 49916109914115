// cardData.js
const cardData = [
    {
        id: 1,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/1.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 2,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/2.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 3,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/3.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 4,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/4.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 5,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/5.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 6,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/6.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 6,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/7.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 6,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/8.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 6,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/9.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 6,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/10.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 6,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/11.png'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 6,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/13.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    },
    {
        id: 6,
        title: "Scooter for Kids 6-12, Scooter for Kids, Foldable and Adjustable with PVC Wheels, Perfect Scooter for Kids 2+ Years - KOXTONS Rs. 1260 - Amazon",
        time: "01:18",
        brandImg: require('../../assets/img/amazon.png'),
        imgUrl: require('../../assets/img/13.jpg'),
        discount: '50%',
        originalPrice: 'Rs 300',
        offerPrice: 'Rs 150'
    }
   
    
];

export default cardData;
