import React, { createContext, useState } from "react";
import Navigation from "./Routes/Navigation";
import { AuthProvider } from "./utils/AuthContext";
import ToastContainerComponent from "./components/ToastContainerComponent";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

const App = () => {
  return (
    // <Provider store={store}>
    //   <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <Navigation />
          <ToastContainerComponent />
        </AuthProvider>
    //   </PersistGate>
    // </Provider>
  );
};

export default App;
