// Navbar.js
import React from 'react';
import { Navbar, Nav, Container, Form, FormControl, Button,NavDropdown } from 'react-bootstrap';
import logo from '../assets/img/brandLogo5.png'
import './header.css';
const DashboardNavbar = () => {
  return (
    <Navbar className='navColor fixed-top' expand="lg"> {/* 'expand="lg"' ensures the burger menu appears on smaller screens */}
  <Container>
    {/* Logo */}
    <img
      src={logo} // Replace with your local image path
      width="75"
      height="70"
      style={{ borderRadius: 15 }}
      className="d-inline-block align-top"
      alt="Brand logo"
    />

    {/* Burger Menu */}
    <Navbar.Toggle aria-controls="basic-navbar-nav" />

    <Navbar.Collapse id="basic-navbar-nav">
      {/* Navigation Links */}
      <Nav className="me-auto navbar-Links">
        {/* <Nav.Link className='navLinkText' href="#home">Home</Nav.Link>
        <Nav.Link className='navLinkText' href="#features">Best Deals</Nav.Link>
        <Nav.Link className='navLinkText' href="#pricing">Trending</Nav.Link> */}
        {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
          <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
        </NavDropdown> */}
      </Nav>

      {/* Search Bar */}
      <Form className="d-flex">
        <FormControl
          type="search"
          placeholder="Search"
          className="me-2"
          aria-label="Search"
        />
        <Button variant="outline-success">Search</Button>
      </Form>

      {/* Login/Sign In Buttons */}
      <Nav className="ml-auto">
        <Nav.Link href="#login">Login</Nav.Link>
        <Nav.Link href="#signin">Sign In</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

  );
}

export default DashboardNavbar;
